.seccion-flexbox {
  display: flex;
  overflow-x: auto;
  justify-content: center;
}

.columna-scrollable {
  flex: 0 0 20%; /* Cada columna ocupa el 25% del ancho */
  height: 100vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.columna-scrollable::-webkit-scrollbar {
  width: 0.5rem;
}

.columna-scrollable::-webkit-scrollbar-track {
  background-color: transparent;
}

.columna-scrollable::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.qt-cont {
  border: solid black;
  margin-top: 27px;
}

.p-qt {
  margin: 20px;
  display: flex;
  flex-direction: column;
}
.name-qt {
  text-align: end;
  font-size: 14px;
  font-family: Light;
  margin-top: 10px;
}

.second-cont-atendeme {
  height: 100vh;
  margin-top: -100vh;
  display: flex;
  /* align-items: flex-start; */
  flex-direction: column;
  justify-content: space-between;
}

.atendeme-title {
  color: #fe6970;
  font-size: 80px;
  margin-left: 122px;
  margin-top: 264px;
  width: fit-content;
  z-index: 100;
  position: absolute;
  bottom: 315px;
}
.atendeme-button {
  position: fixed;
  bottom: 50px;
  right: 60px;
}

/* Gallery */
.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 20px; /* Adjust the gap between the boxes as needed */
  justify-items: center;
  align-items: center;
  /* overflow: hidden; */
  height: 100vh;
  transform: translateY(
    calc(50% - 100vh / 2 - 175px)
  ); /* Adjust the translateY value */
}

.grid-container:hover {
  transform: translateY(0%);
}

.grid-container::before,
.grid-container::after {
  content: ""; /* Add this line back */
  display: block;
}

.grid-container::before {
  grid-column: 1 / 6; /* Extend across all columns */
  grid-row: 1 / 2; /* Place before the first row */
}

.grid-container::after {
  grid-column: 1 / 6; /* Extend across all columns */
  grid-row: 4 / 5; /* Place after the last row */
}

.grid-container > div {
  width: 300px; /* Adjust the width to make the boxes wider */
  height: 300px; /* Adjust the height to make the boxes taller */
  /* background-color: */
  margin: 0 10px; /* Adjust the horizontal margin as needed */
}

.box,
.box img,
.box video {
  width: 100%;
  height: 100%;
  background-size: cover;
  transition: transform 0.3s;
  border: solid black 0.5px;
  position: relative; /* Add this line */
}

/* Style for hovering over both images and videos */
.box:hover,
.box:hover img,
.box:hover video {
  transform: scale(1.1);
  z-index: 100;
}

/* Additional styling for the video element */
.box video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Styling for the image text overlay */
.image-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0px;
  color: white;
  font-size: 14px;
}

.image-text {
  display: flex;
  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  color: #fff;
  height: 100%;
  width: 100%;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
  align-items: flex-end;
  justify-content: space-between;
}
.box:hover .image-text {
  opacity: 1;
}
/* Pruebas */

.expanded-container {
  width: 120vw; /* Make the container 20% wider than the viewport */
  height: 120vh; /* Make the container 20% taller than the viewport */
  /* margin: -10vh -10vw; Center the container horizontally and vertically */
  background-color: gray; /* Set the background color to gray */
  transition: transform 0.2s ease; /* Add a smooth transition effect */
}
.expanded-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

/* .container {
  width: 100vw;
  height: 100vh;
  background-color: red;
  position: absolute;
} */

/* Pushmorgen */
.cont-push-morgen {
  display: flex;
  justify-content: center;
  z-index: 10;
}

.container-push {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: -50px; */
}
/* .interactivo-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
} */

/* Particles */
.particles-cont {
  height: 100vh;
}

/* Morgen Falling */

.morgens-falling {
  position: sticky;
}

.image-falling-button {
  position: relative;
  width: 100%;
  height: 400px; /* Adjust the height as needed */
}

.falling-images {
  position: absolute;
  bottom: 0;
  left: 0;
}

.falling-images img {
  position: absolute;
  width: 50px; /* Adjust the width as needed */
  animation: fallingAnimation 1s linear forwards,
    bouncingAnimation 0.3s ease-in-out infinite;
}

@keyframes fallingAnimation {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes bouncingAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Malo */

.falling-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.falling-text-cont {
  position: absolute;
  display: flex;
  align-items: center;
}
.title-falling {
  font-size: 55px;
}

.out {
  display: none;
}

.falling-button {
  margin-left: 50px;
  padding: 20px;
  padding-left: 80px;
  padding-right: 80px;
  border-radius: 100px;
  border: solid 1px black;
  background-color: #dccb00;
  user-select: none;
}

/* Comunidad */

.comunidad-cont {
  width: 100%;
  height: 100vh;
  display: flex;
  min-width: 1000px;
}

.comunidad-title {
  font-size: 80px;
  margin-bottom: 0px;
  width: 500px;
}
.comunidad-p {
  font-size: 18px;
  width: 500px;
}
.comunidad-spike {
  position: relative;
  left: 165px;
}

.comu-gallery-atendeme {
  margin: 0;
  margin-bottom: 40px;
  font-size: 40px;
  color: white;
}

.comunidad-left {
  width: 60%;
}

.comunidad-right {
  width: 40%;
}
.comu-right-top {
  height: 50%;
  background-color: #dc3349;
  margin: 0;
  border: solid black;
  border-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.comu-right-bottom {
  height: 50%;
  background-color: #dccb00;
  margin: 0;
  border: solid black;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.comunidad-left-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

.button-text-comu {
  margin-left: 60px;
}

@media (max-height: 729px) {
  .comunidad-left-content {
    margin-top: 20px;
  }
}

@media (max-width: 1100px) {
  .button-text-comu {
    margin-left: 40px;
  }
}

/* YoMor */

.apoyo-yomor {
  margin-right: 25px;
  font-family: "Light";
  font-size: 18px;
}
.yoMor-text {
  color: white;
  width: 500px;
  font-size: 25px;
  margin-left: 265px;
}

.yoMor-text-link {
  display: flex;
  position: absolute;
  color: white;
  font-size: 20px;
  font-family: "Light";
  margin-left: 265px;
  text-decoration: underline;
  /* z-index: 1000; */
  pointer-events: auto;
}
.spikes {
  position: relative;
  background: #dccb00;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 650px;
}

.position-spike-obras {
  top: 0px;
  left: 250px;
}

.position-spike-comu {
  top: 290px;
}
.spikes-title {
  font-size: 50px;
}

.ailin-title {
  font-size: 50px;
}

.sponsor-caba {
  margin-right: 100px;
  margin-left: 70px;
  width: 225px;
  height: 65px;
}
.sponsor-itau {
  width: 225px;
}
.yoMor-text-grande {
  position: absolute;
  margin-top: -150px;
}

@media (min-width: 1700px) {
  .yoMor-text-grande {
    position: absolute;
    margin-top: -300px;
  }
}

@media (max-width: 1300px) {
  .yoMor-text {
    margin-left: 100px;
    /* width: 40%; */
    /* font-size: 25px; */
    font-size: 22px;
  }
  .yoMor-text-link {
    margin-left: 100px;
    font-size: 18px;
  }
}
@media (max-width: 1135px) {
  .yoMor-text {
    margin-left: 100px;
    width: 35%;
    /* font-size: 25px; */
    font-size: 20px;
  }
  .falling-text-cont {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
@media (max-width: 1000px) and (min-width: 700px) {
  .yoMor-text {
    margin-left: 100px;
    width: 80%;
    font-size: 25px;
  }
}

.spikes::before {
  content: "";
  position: absolute;
  right: 0;
  left: -0%;
  bottom: 100%;
  z-index: 10;
  display: block;
  height: 50px;

  background-size: 70px 100%;
  background-image: linear-gradient(45deg, #dccb00 33.33%, transparent 33.33%),
    linear-gradient(-45deg, #dccb00 33.33%, transparent 33.33%);
  background-position: 0 100%;
}

/* star */
.footer-yomor {
  position: absolute;
  background-color: #dccb00;
  height: 130px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 200px;
  padding-bottom: 40px;
  z-index: 100;
}

.spikeWidth {
  width: 290px;
  height: auto;
}
.cont-svgs-obras {
  position: absolute;
  right: 180px;
  top: 55px;
  width: 290px;
  height: auto;
}

.cont-svgs-comunidad {
  position: absolute;
  margin-top: 0px;
  right: 400px;
  width: 290px;
  height: auto;
  z-index: 100;
  /* border: red solid 2px !important; */
}
.cont-svgs-3 {
  width: 290px;
  height: 210px;
  z-index: 100;
}

@media (max-width: 1200px) {
  .spikes {
    justify-content: flex-start;
    padding-left: 100px;
  }
}

@media (max-width: 1110px) {
  .cont-svgs-obras {
    right: 15px;
    top: 190px;
    width: 231px;
    height: auto;
  }
  .cont-svgs-comunidad {
    right: 250px;
    top: 400px;
    width: 231px;
    height: auto;
  }
  .footer-yomor {
    position: relative;
    background-color: #dccb00;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 40px;
    flex-direction: column;
  }
  .position-spike-obras {
    top: 0px;
    left: 150px;
  }

  .position-spike-comu {
    top: 290px;
  }
  .spikeWidth {
    width: 230px;
    height: auto;
  }
}
@media (max-width: 900px) {
  .spikes-title {
    font-size: 25px;
  }
  .ailin-title {
    font-size: 22px;
  }
  .spikes {
    /* min-height: 450px; */
  }
}
@media (max-width: 770px) {
  .cont-svgs-3 {
    position: relative;
    width: 160px;
  }
}
@media (max-width: 720px) {
  .yoMor-text {
    margin-left: 10px;
    width: 90%;
    font-size: 18px;
  }
  .spike-yomor {
    /* display: none; */
  }
  .spikes {
    /* width: 100vw; */
    height: 35vh;
  }

  .ailin-title {
    font-size: 22px;
  }
  .footer-yomor {
    position: relative;
    background-color: #dccb00;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 40px;
    flex-direction: column;
  }
  .sponsor-caba {
    margin-left: 0px;
    width: 120px;
    height: auto;
    margin-right: 60px;
  }
  .sponsor-itau {
    width: 110px;
  }
  .apoyo-yomor {
    margin-right: 0px;
    margin-left: 10px;
    font-size: 12px;

    font-family: "Regular";
  }
  .yoMor-text-link {
    margin-left: 25px;
  }
  .spikes::before {
    background-size: 40px 100%;
  }
  .yoMor-text-grande {
    position: absolute;
    /* margin-top: -70px; */
    margin-top: 0px;

    margin-left: 14px;
  }
  .cont-svgs-obras {
    /* position: absolute; */
    right: 160px;
    top: 245px;
    width: 140px;
    /* height: auto; */
  }

  .cont-svgs-comunidad {
    /* position: absolute; */
    /* top: 82px; */
    /* right: 20px; */
    width: 130px;
    /* z-index: 100; */
  }

  .yomor-cont-mob {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
  }
  .yomor-mob-title {
    color: white;
    position: absolute;
    font-size: 50px;
    text-align: center;
    margin-top: -50px;
  }
  .ailin-title {
    margin-right: 65px;
  }
  .cont-svgs-3 {
    position: relative;
    width: 160px;
  }
  .comunidad-cont {
    width: 100vw;
    display: flex;
    flex-direction: column;
    min-width: 340px;
  }
  .comunidad-title {
    font-size: 50px;
    width: -webkit-fill-available;
    text-align: center;
  }
  .comunidad-p {
    width: -webkit-fill-available;
    font-size: 14px;

    margin-left: 40px;
    margin-right: 25px;
  }
  .comunidad-left {
    width: 100%;
  }
  .comunidad-left-content {
    margin-top: 50px;
  }
  .comunidad-spike {
    position: relative;
    left: 50px;
  }
  .comunidad-right {
    width: 100vw;
  }
  .comu-right-top {
    height: 85%;
  }
  .comu-right-bottom {
    height: 85%;
  }
  .button-text-comu {
    margin: 0;
    width: 100%;
  }
  .comu-buttons {
    display: flex;
    justify-content: center;
  }
  .comu-gallery-atendeme {
    margin-left: 30px;
  }
  .gallery-mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
  }
  .gallery-box-mob {
    width: 320px;
    height: 290px;
    margin-bottom: 10px;
    background-size: cover;
    border: solid 1px;
  }
  .gallery-title-mob {
    color: #dc3349;
    font-size: 50px;
    width: 320px;
  }
  .columna-scrollable {
    flex: none;
    width: 300px;
    margin-top: 200px;
  }
  .atendeme-title {
    color: #fe6970;
    font-size: 50px;
    margin-left: 0;
    margin-top: 120px;
    width: 100vw;
    z-index: 100;
    display: flex;
    justify-content: center;
    top: -5px;
    z-index: -1;
    /* bottom: 0px; */
  }
  .atendeme-cont-title-mob {
    display: flex;
    justify-content: center;
    width: 100vw;
  }
  .atendeme-cont-button-mob {
    width: 97vw;
    height: 100vh;
    align-items: flex-end;
    margin-bottom: 15vh;
    /* 25svh */
    display: flex;
    justify-content: center;
  }
  .atendeme-button {
    position: inherit;
  }
  .seccion-flexbox {
    height: 100vh;
  }
  .second-cont-atendeme {
    flex-direction: column-reverse;
  }
  .participar-popup {
    position: absolute;
    top: 15px;
    left: 20px;
  }

  .title-falling {
    font-size: 36px;
    line-height: 40px;
  }
  .falling-button {
    margin-left: 0px;
  }
  .falling-cont {
    align-items: flex-start;
    padding-top: 50px;
  }
  .out {
    display: inline;
  }

  .spikes {
    min-height: 300px;
  }

  .position-spike-obras {
    top: 250px;
    left: 150px;
  }

  .position-spike-comu {
    top: 340px;
  }
  .spikeWidth {
    width: 120px;
    height: auto;
  }
}

@media (max-width: 500px) {
  .spikes {
    /* justify-content: flex-start; */
    padding-left: 15%;
  }
}

@media (max-width: 420px) {
  .cont-svgs-obras {
    /* position: absolute; */
    /* right: 127px; */
    /* top: 230px; */
    width: 140px;
    /* height: auto; */
  }

  .cont-svgs-comunidad {
    /* position: absolute; */
    /* top: 130px; */
    /* right: 20px; */
    width: 130px;
    /* z-index: 100; */
  }
  .footer-yomor {
    /* padding-top: 180px !important; */
  }
}

@media (max-width: 600px) {
  .spikes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 60px;
  }
  .position-spike-obras {
    top: -125px;
    left: 180px;
  }
  .position-spike-comu {
    top: 10px;
    left: 90px;
  }
  .spikeWidth {
    width: 120px;
    height: auto;
  }
}

@media (max-width: 370px) {
  .yomor-mob-title {
    font-size: 40px;
  }
}

.prueba-atendeme {
  display: flex;
}

@media (width > 750px) and (max-height: 700px) {
  .cont-svgs-3 {
    width: 200px;
    height: auto;
  }
  .comunidad-spike {
    bottom: 30px;
  }
}

@media (max-width: 350px) {
  .spikes {
    /* justify-content: flex-start; */
    padding-left: 10px;
  }
}
/* .morYo-text {
  color: white;
  width: 40%;
  font-size: 25px;
  margin-left: 107px;
}
.chauchau {
  height: 650px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;
} */

.caca {
  width: 50vw;
  height: 550px;
  position: relative;
}
