body {
  cursor: url("../Assets/svg/cursor-final.svg"), auto;
  font-family: "Regular";
  margin: 0 auto !important;
}

.relative {
  position: relative;
}

.disableSave {
  -webkit-user-select: none !important;
  -webkit-touch-callout: none !important;
}

.cursor-morgen {
  cursor: url("../Assets//svg/pointer-morgen.svg"), auto;
}

.full-h {
  height: 100vh;
}

@media (min-height: 800px) {
  .full-h {
    height: 890px;
  }
}

.no-scroll {
  overflow: hidden;
}

.no-scroll-interactivo {
  overflow: hidden;
}

/* @media (max-height: 630px) {
  .no-scroll {
    overflow: auto;
  }
} */

.pointer {
  cursor: url("../Assets/svg/pointer-final.svg"), auto !important;
}
.grab {
  cursor: url("../Assets/svg/cursor-grab.svg"), auto !important;
}
a {
  cursor: url("../Assets/svg/pointer-final.svg"), auto !important;
  text-decoration: none;
  color: inherit;
}

.cursor-balde {
  cursor: url("../Assets/svg/pointer-balde-2.svg"), auto;
}

h2 {
  font-size: 30px;
  font-family: "SemiBold";
}

h4 {
  font-family: "Medium";
  font-size: 18px;
  line-height: 22px;
}

.custom-btn__text {
  color: white;
}

.inside {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.mob-home-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 900;
}

.mob-home-popup-content {
  width: 364px;
  height: 448px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  background-image: url("../Assets/frame-prueba.png");
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
}
.p-pop-up-mob {
  font-size: 35px;
  text-align: center;
  padding-bottom: 25px;
}

/* #full-screen {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -100vh;
} */

.wrapper {
  /* width: 100vw; */
  /* height: 100vh; */
  background-color: black;
  background-size: cover;
  background-repeat: none;
}

#full-screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 775px;
  max-height: 1100px;
}

.animated-circle-cont {
  width: 100vw;
  display: flex;
  justify-content: center;
  max-height: 1100px;
}

.circulo-animado {
  position: absolute;
  width: 696px;
  height: 696px;
  animation: rotate 30s infinite linear;
  display: flex;
  justify-content: center;
  /* align-items: center; */

  min-height: 775px;
  max-height: 1100px;
  margin-top: 50px;
  /* margin-top: -110vh; */
  /* max-height: 1000px; */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.contenedor-custom {
  height: 100vh;
  width: 100vw;
  text-align: center;
  margin-top: 100px;
}
.circle-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  height: 100vh;
  min-height: 775px;
  margin-top: -20px;
  max-height: 1000px;
}

.cont-home-pop {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}
@media (max-height: 720px) {
  .circle-container {
    margin-top: -51px;
  }
}
.circle-wrapper {
  position: relative;
}

.circle-button {
  position: absolute;
}

.nav-bar {
  position: fixed;
  top: 50px;
  right: 70px;
  z-index: 999;
  display: flex;
  align-items: center;
}

.plus-icon {
  display: flex;
  -webkit-tap-highlight-color: transparent;
}
.menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: #dfb000;
  color: #fff;
  transition: all 1s ease;
  transform: translateX(100%);
  z-index: 999;
}

.menu-open {
  transform: translateX(0%);
}

/* NAVBAR */
.navbar-container {
  display: flex;
  height: 100vh;
}

.navbar-left-section {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
}

.navbar-right-section {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-right-section-desk {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-title {
  font-size: 55px;
  line-height: 0;
  color: black;
}
.navbar-title-mob {
  font-size: 55px;
  line-height: 0;
  color: black;
}

@media (max-height: 680px) {
  /* .navbar-title {
    font-size: 45px;
  } */
  .navbar-title-mob {
    font-size: 45px;
  }
}

@media (max-width: 1180px) {
  /* .navbar-title {
    font-size: 45px;
  } */
  .navbar-title-mob {
    font-size: 45px;
  }
}

.toggle-button {
  display: flex;
  justify-content: center;
  width: 100px;
  height: 30px;
  border: solid black;
  margin-left: 100px;
}

.toggle-button-link {
  display: inline-block;
  width: 50px;
  height: 30px;
  background-color: transparent;
  color: black;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.toggle-button-pay {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toggle-button-link.active {
  background-color: black;
  color: #dfb000;
}
.contact {
  margin-left: 100px;
  color: black;
}
.contact-me {
  margin-left: 100px;
}

.contact-me-obras {
  margin-left: 100px;
}

.news {
  font-size: 14px;
  font-family: Medium;
  line-height: 17px;
  color: black;
}

.email-container {
  width: 330px;
  height: 45px;
  position: relative;
}

.email-input {
  width: 83%;
  height: 100%;
  background: transparent;
  border: solid;
  border-width: 1px;
  border-color: black;
  padding-left: 10px;
  padding-right: 45px;
  font-family: Medium;
  border-radius: 0;
  touch-action: manipulation !important;
}
.email-input::placeholder {
  color: black;
}

.email-input:focus {
  outline: black;
}
/* 
.submit-button {
  position: absolute;
  right: 0px;
  top: 1px;
  width: 28%;
  height: calc(100% - -2px);
  background: #dccb00;
  border: none;
  color: black;
  font-size: 16px;
  cursor: pointer;
  font-family: Medium;
  border-left: 1px solid black;
} */

.email-input,
.submit-button {
  box-sizing: border-box;
}

.submit-button {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 28%;
  height: 100%; /* Adjust the percentage as needed */
  background: #dccb00;
  border: none;
  color: black;
  font-size: 16px;
  cursor: pointer;
  font-family: Medium;
  border: 1px solid black;
}

.yoMor:hover {
  color: #dc3349;
  transition: color 0.5s;
}
.obras:hover {
  color: #005da2;
  transition: color 0.5s;
}
.comunidad:hover {
  color: #ae79ef;
  transition: color 0.5s;
}
.revista:hover {
  color: #7d9f00;
  transition: color 0.5s;
}
.interactivo:hover {
  color: #4590e6;
  transition: color 0.5s;
}
.merch:hover {
  color: #dc3349;
  transition: color 0.5s;
}
.agenda:hover {
  color: #ff3910;
  transition: color 0.5s;
}

/* pop-upcont */
.popup-container {
  position: fixed;
  top: 70px;
  bottom: 70px;
  left: 100px;
  right: 100px;
  background-color: var(--color);
  padding: 20px;
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: scale(0.8);
  min-height: 450px;
  min-width: 700px;
  border: solid black;
}

.popup-container.open {
  opacity: 1;
  transform: scale(1);
}

.popup-container .close-button {
  position: absolute;
  top: 50px;
  right: 50px;
  /* cursor: pointer; */
  color: #fff;
  transition: color 0.3s ease;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.popup-overlay.open {
  opacity: 1;
  pointer-events: auto;
}

.participar-popup {
  position: absolute;
  top: 50px;
  left: 50px;

  color: white;
  font-family: SemiBold;
}

/* FORM SUPPORT */
.support-cont {
  display: flex;
  justify-content: space-around;

  margin-right: 60px;
  align-items: center;
  height: 100%;
}

.support-h3 {
  font-size: 60px;
  margin: 0px;
  font-family: "Light";
}

.support-p {
  font-size: 18px;
}

/* FORM */

.form-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.form-row-mob {
  display: inherit;
}

.special-form h3 {
  margin-right: 10px;
  color: white;
  font-size: 40px;
}

.special-form {
  display: flex;
  flex-direction: column;
  border: none;
  width: 100%;
  align-content: center;
  flex-wrap: wrap;
}

.special-form input,
.special-form textarea {
  border: none;
  background-color: transparent;
  outline: none;
  color: white;
  font-size: 40px;
  font-family: Medium;
  width: 100px;
}

.popup-form-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media (max-width: 720px) {
}

.special-form input::placeholder,
.special-form textarea::placeholder {
  /* color: rgba(0, 0, 0, 0.4); */
  color: #ffffff;
  opacity: 0.21;
  font-size: 40px;
  font-family: Medium;
}

.special-form input[type="number"]::-webkit-inner-spin-button,
.special-form input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  display: none;
}

.special-form input[type="number"] {
  width: 90px;
}

.special-form input[type="text"] {
  width: 205px;
}

.special-form textarea {
  width: 950px;
  height: 200px;
  margin-left: -8px;
  resize: none;
}

.p-popupform {
  font-size: 18px;
  margin-right: 20px;
  width: 750px;
}

@media (max-width: 1260px) {
  .special-form {
    width: 80%;
    flex-wrap: unset;
  }
}

@media (max-width: 1200px) {
  .special-form h3 {
    font-size: 25px;
  }
  .special-form input::placeholder,
  .special-form textarea::placeholder {
    color: #ffffff;
    opacity: 0.21;
    font-size: 30px;
    font-family: Medium;
  }
  .special-form input {
    font-size: 25px;
  }
  .special-form textarea {
    font-size: 25px;
  }
  .p-popupform {
    font-size: 17px;
  }
  .form-row {
    margin-bottom: 0px;
  }
  .special-form input[type="text"] {
    width: 115px;
  }
  .special-form input[type="number"] {
    width: 60px;
  }
}

/* top: 190px;
bottom: 190px;
right: 180px;
left: 180px; */
/* mercadopafgo button */

:root {
  --switches-bg-color: #7d9f00;
  --switches-label-color: #000;
  --switch-bg-color: #dccb00;
  --switch-text-color: #000;
}

.switches-container {
  width: 16rem;
  position: relative;
  display: flex;
  padding: 0;
  position: relative;
  background: var(--switches-bg-color);
  line-height: 3rem;
  border-radius: 3rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border: solid;
  font-family: Medium;
}

/* input (radio) for toggling. hidden - use labels for clicking on */
.switches-container input {
  visibility: hidden;
  position: absolute;
  top: 0;
}

/* labels for the input (radio) boxes - something to click on */
.switches-container label {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  /* cursor: pointer; */
  color: var(--switches-label-color);
}

/* switch highlighters wrapper (sliding left / right) 
    - need wrapper to enable the even margins around the highlight box
*/
.switch-wrapper {
  position: absolute;
  /* top: -1px; */
  /* right: 128px; */
  bottom: 0px;
  width: 50%;
  padding-top: 1px;
  z-index: 2;
  transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  /* transition: transform 1s; */
  /* height: 43px; */
}

/* switch box highlighter */
.switch {
  border-radius: 3rem;
  background: var(--switch-bg-color);
  height: 100%;
}

.switch div {
  width: 100%;
  text-align: center;
  opacity: 0;
  display: block;
  color: var(--switch-text-color);
  transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
  will-change: opacity;
  position: absolute;
  top: 0;
  left: -1px;
  border: solid;
  border-radius: 100px;
  background-color: #dccb00;
}

/* slide the switch box from right to left */
.switches-container input:nth-of-type(1):checked ~ .switch-wrapper {
  transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-container input:nth-of-type(2):checked ~ .switch-wrapper {
  transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-container
  input:nth-of-type(1):checked
  ~ .switch-wrapper
  .switch
  div:nth-of-type(1) {
  opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-container
  input:nth-of-type(2):checked
  ~ .switch-wrapper
  .switch
  div:nth-of-type(2) {
  opacity: 1;
}

.right-cont-pop-community {
  align-items: center;
  display: flex;
  justify-content: center;
}

.support-button {
  height: 80px;
  width: 16rem;
  border-radius: 100px;
  border: solid;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Medium;
}

.support-button:hover {
  background: #dccb00;
}

.support-button-trans {
  height: 80px;
  width: 16rem;
  border-radius: 100px;
  border: solid transparent;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Medium;
  color: transparent;
}
@media (max-height: 675px) {
  .popup-container .close-button {
    top: 25px;
    right: 25px;
  }
}

@media (max-width: 1200px) {
  .support-button {
    height: 60px;
    width: 200px;
  }
  .switches-container {
    line-height: 2.5rem;
  }
  .support-h3 {
    font-size: 50px;
  }
}

@media (max-width: 1150px) {
  .support-h3 {
    font-size: 40px;
  }
}

/* AGENDA */
.agenda-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
}
.left-agenda-cont {
  display: flex;
  width: 50vw;
  /* background-image: url("../Assets/perro-morgen.png"); */
  background-image: url("../Assets/fechas/morgen-colores.png");

  height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  align-items: center;
}

.right-agenda-cont {
  /* width: 40%; */
  width: 50vw;
  /* border-left: 2px solid black; */
  height: 100vh;
  overflow-y: visible;
}

.fechas-titulo {
  font-size: 90px;
  margin-left: 150px;
}

.agenda-button {
  position: absolute;
  top: 225px;
  left: 310px;
}

.agenda-fixed-absolute {
  position: fixed;
}

/* timeline */

.timeline {
  margin-left: 12px;
  overflow: visible;
  padding-top: 105px;
  position: relative;
  border-left: 2px solid black;
}

.vw-complete-flex-left {
  width: 100vw;
  display: flex;
  justify-content: end;
}

.entry {
  margin-left: 20px;
  position: relative;
  padding-left: 100px;
  /*   border: 2px solid black; */
  /*   border-radius: 5px; */
  min-width: 100px;
  max-width: 150px;
  height: auto;
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.entry:after {
  content: "";
  position: absolute;
  display: block;
  width: 65px;
  height: 2px;
  background: black;
  top: 35%;
  left: -8%;
}

.entry:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 180%;
  display: block;
  border-radius: 0px;
  /* background: black; */
  left: -19%;
}

.core {
  width: 400px;
  height: inherit;
  margin-left: 55px;
}

/* Agenda tabley */

@media (max-width: 1150px) {
  .agenda-button {
    top: 225px;
    left: 210px;
  }
  .fechas-titulo {
    margin-left: 25px;
  }
  .right-agenda-cont {
    width: 54vw;
  }
}

/* Agenda mobile */

@media (max-width: 1000px) {
  .vw-complete-flex-left {
    justify-content: flex-start;
    margin-left: 15px;
  }

  .timeline {
    padding-top: 225px;
    z-index: -1;
    width: calc(100vw - 30px);
  }
  .entry {
    max-width: none;
    padding-left: 0px;
    margin-left: 7%;
  }
  .core {
    width: auto;
    margin-left: 30px;
    margin-right: 25px;
  }
  .entry:after {
    top: 16.5%;
  }
  .left-agenda-cont {
    height: 100vh;
    width: auto;
    background: none;
    display: block;
  }
  .fechas-titulo {
    font-size: 58px;
    margin-top: 190px;
    margin-left: 70px;
  }
  .agenda-button {
    display: none;
  }
  .agenda-fixed-absolute {
    position: absolute;
  }
  .fecha-desc {
    font-size: 14px;
  }
}

@media (max-width: 550px) {
  .entry:after {
    width: 45px;
  }
}

/* news popup */

.news-popup {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  width: 500px;
  height: 260px;
  background-color: #fe6970; /* Adjust background color as needed */
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s ease-in-out, visibility 2s ease-in-out;
  z-index: 1000;
  border: solid black;
}

.news-popup.show {
  opacity: 1;
  visibility: visible;
  z-index: 997;
}

.accordion {
  display: flex;
  justify-content: flex-end;
}

.accordion-tab {
  width: 58px;
  border: 0.5px solid #000;
  box-sizing: border-box;
  height: 100vh;
  /* cursor: pointer; */
  transition: background-color 0.8s ease;
  z-index: 100;
  transition: width 0.2s;
}

.accordion-tab:hover {
  width: calc(58px + 20px);
}

.accordion-content {
  width: 0;
  overflow: hidden;
  transition: width 0.8s ease;
  z-index: 100;
}

.accordion-content.active {
  width: 100%;
  overflow: visible;
  z-index: 200;
}

.obras-left {
  position: absolute;
  height: 100vh;
  display: flex;
  align-items: center;
  margin-left: 100px;
  z-index: 0;
}

.obras-spike-button {
  position: absolute;
  bottom: 144px;
  left: 500px;
}

@media (max-width: 1250px) {
  .obras-spike-button {
    bottom: 280px;
    left: 280px;
  }
}

.accordion-title {
  transform: rotate(-90deg);
  margin-top: 37vh;
  font-size: 20px;
  text-transform: capitalize;
  white-space: nowrap;
}
.accordion-subtitle {
  transform: rotate(-90deg);
  margin-top: 44vh;
  font-family: Light;
  white-space: nowrap;
  color: transparent;
  text-transform: capitalize;
}

.accordion-tab:hover .accordion-subtitle {
  color: black;
}

.flechita {
  fill: transparent;
}

.accordion-tab.active .flechita {
  fill: black !important;
}

.accordion-tab.active .accordion-subtitle {
  color: transparent;
}

/* conexion intestable */

.image-gallery-conexion {
  display: flex;
  flex-direction: row;
  align-items: left;
  /* overflow-x: hidden; */
  height: 100vh;
}

.conextion-row {
  overflow-x: hidden !important;
  width: 100%;
}

.conextion-row::-webkit-scrollbar {
  width: 0px;
}

.conextion-row::-webkit-scrollbar-track {
  background-color: transparent;
}

.conextion-row::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.image-container-conexion {
  position: relative;
}

.conexion-images {
  width: 100%;

  object-fit: cover;
  margin-bottom: -5px;
}

.popup-conexion {
  position: fixed;
  top: 70px;
  bottom: 70px;
  left: 115px;
  right: 115px;
  background-color: white;
  padding: 20px;
  border: 3px solid black;
  text-align: left;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-width: 950px;
}

.popup-conexion .close-button {
  position: absolute;
  top: 50px;
  right: 50px;
}

.popup-conexion img {
  width: 350px;
  object-fit: contain;
  margin-bottom: 10px;
}

.popup-conexion-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.popup-conexion-title {
  font-size: 40px;
  margin-bottom: 5px;
  text-align: left;
}
.popup-conexion-subtitle {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: Bold;
}
.popup-conexion-caracteristicas {
  font-size: 17px;
  margin-top: 10px;
  margin-bottom: 0px;
  text-align: left;
}
/* .popup-conexion input {
  padding: 5px;
  margin-bottom: 10px;
} */

/* .popup-conexion button {
  padding: 5px 10px;
} */

.popup-trigger-conexion {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

/* Inadvertido */

.horizontal-inad-gallery {
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.inad-container {
  flex: 0 0 55%;
  margin-right: 10px;
}

.gallery-inad-image {
  width: 100%;
  /* height: 100vh; */
  /* EL TEMA ES LAS IMAGENES QUE ELIGE */
}
.horizontal-inad-gallery-container {
  background-color: white;
  overflow-x: scroll;
}

.icontag-inad {
  position: relative;
  bottom: 60px;
  right: 90px;
}

/* HolaMorgen */

.book-container {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: white;
}

/* futuro */
.horizontal-futuro-gallery-container {
  background-color: white;
  overflow-x: scroll;
}

.horizontal-futuro-gallery {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 100vh;
}

.futuro-container {
  flex: 0 0 70%;
  margin-left: 20px;
}

.gallery-futuro-image {
  width: 100%;
  /* height: 70vh; */
  /* EL TEMA ES LAS IMAGENES QUE ELIJE */
}
.last-image .gallery-futuro-image {
  margin-right: 20px;
}

/* 1270 x 690 */
/* @media (min-height: 690px) {
} */

/* gallery  */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.797);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
}

/* Frame 41 */

.modal {
  position: relative;
  background-color: transparent;
  width: auto;
  overflow: hidden;
}

@media (max-height: 760px) {
  .modal {
    /* width: 500px; */
  }
}

.modal-image {
  width: 100%;
  height: 90%;
  max-height: 580px;
  object-fit: contain;
}

@media (max-height: 670px) {
  .modal-image {
    width: 100%;
    height: 90%;
    max-height: 500px;
  }
}

@media (max-height: 620px) {
  .modal-image {
    width: 100%;
    height: 90%;
    max-height: 400px;
  }
}

.submodal {
  height: 10%;
  color: white;
  /* background: #000000;
  opacity: 0.85; */
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  /* cursor: pointer; */
  font-size: 24px;
  color: #000;
}

.gallery-prev-button,
.gallery-next-button {
  /* position: absolute; */
  top: 50%;
  /* transform: translateY(-50%); */
  padding: 8px;
  font-size: 16px;
  background-color: transparent;
  color: #fff;
  border: none;
  /* cursor: pointer; */
}

.gallery-prev-button {
  margin-right: 40px;
}

.gallery-next-button {
  margin-left: 40px;
}

.submodal-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 10px;
}
.submodal-bottom {
  display: flex;
  justify-content: space-between;
  font-size: 17px;
  font-family: "semiBold";
}

.submodal-category {
  color: black;
  border: solid #dccb00;
  background-color: #dccb00;
  width: 85px;
  height: 20px;
  text-align: center;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.line-interactivo-cont {
  width: 100vw;
  display: flex;
  justify-content: center;
  z-index: 990;
  bottom: 60px;
}

/* MOBILE */

.mob-links-cont {
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mob-links-home {
  color: white;
  margin-bottom: 10px;
}

.mob-home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}
.mob-img-cont {
  display: flex;
  justify-content: center;
}

@media (max-height: 600px) {
  .mob-links-home {
    display: block;
    color: white;
    margin-bottom: 5px;
  }
}

/* Accordion Mobile */

.accordion-item-mob {
  border: 1px solid #000;
}

.accordion-header-mob {
  padding: 10px;
  margin-bottom: -1px;
}
.accordion-title-mob {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: bold;
}
.accordion-content-mob {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.accordion-item-mob.active-mob .accordion-content-mob {
  max-height: 1000px; /* You can set any appropriate height value here */
  transition: max-height 1.5s ease;
}

.acc-close-icon-mob {
  font-weight: bold;
  font-size: 18px;
}
.obras-welcome {
  display: flex;
  justify-content: space-around;
}

.button-entendido {
  background: #dc3349;
  width: 288px;
  height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: solid 1px black;
  color: white;
}

@media (max-width: 1200px), (max-height: 700px) {
  .navbar-title {
    font-size: 45px;
  }
  .navbar-container {
    display: flex;
    height: 92vh;
  }

  .accordion-subtitle {
    margin-top: 35vh;
  }
  .obras-spike-button {
    bottom: 180px;
    left: 165px;
  }
  .obras-title {
    margin-bottom: 300px;
  }
  /* .special-form input[type="text"] {
    width: 126px;
  }
  .special-form input[type="number"] {
    width: 69px;
  } */
}

@media (max-width: 1000px), (max-height: 570px) {
  .navbar-title {
    font-size: 35px;
  }
}
/* .mob-home-container */
@media (max-width: 1320px) {
  .book-container {
    flex-direction: column;
  }
  .controls-style {
    padding-top: 50px;
  }
}

@media (max-width: 768px) {
  .navbar-container {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }
  /* .navbar-title {
    font-size: 33px;
  } */
  .navbar-title-mob {
    font-size: 33px;
  }
  .navbar-right-section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-top: 100px;
    margin-left: 40px;
  }
  .navbar-left-section {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column-reverse;
  }
  .contact-me {
    margin-left: 40px;
    margin-top: 20px;
  }
  .contact {
    margin-left: 0px;
    color: black;
    display: flex;
    width: 50%;
    justify-content: space-evenly;
    margin-top: 30px;
  }
  .contact-icons-text {
    display: none;
  }
  .contact-icons {
    width: 20px;
  }
  .email-input {
    border-radius: 0px;
  }

  .nav-bar {
    margin-left: 0px;
    top: 0px;
    right: 0px;
    width: 50vw;
    display: flex;
    justify-content: space-between;
  }
  .toggle-button {
    position: absolute;
    margin-left: 40px;
    margin-top: 30px;
  }
  .plus-icon {
    margin-top: 25px;
    margin-right: 30px;
  }
  .support-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-left: 0px;
    overflow: auto;
    margin-top: 0px;
    margin-right: 0px;
  }
}

@media (max-width: 720px) {
  .navbar-container {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }

  .navbar-title-mob {
    font-size: 33px;
  }
  .navbar-right-section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-top: 100px;
    margin-left: 40px;
  }
  .navbar-left-section {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column-reverse;
  }
  .contact-me {
    margin-left: 40px;
    margin-top: 20px;
  }
  .contact {
    margin-left: 0px;
    color: black;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 30px;
  }
  .contact-icons-text {
    display: none;
  }
  .contact-icons {
    width: 20px;
  }

  .email-input {
    border-radius: 0px;
  }

  /* .submit-button {
    height: 112% !important;
    top: 1px;
  } */
  .nav-bar {
    margin-left: 0px;
    top: 0px;
    right: 0px;
    width: 50vw;
    display: flex;
    justify-content: space-between;
  }
  .toggle-button {
    position: absolute;
    margin-left: 40px;
    margin-top: 30px;
  }
  .plus-icon {
    margin-top: 25px;
    margin-right: 30px;
  }
  .support-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-left: 0px;
    overflow: auto;
    margin-top: 0px;
    margin-right: 0px;
  }
  .right-cont-pop-community {
    margin-top: 55px;
  }
  .support-button {
    width: 16rem;
    height: 60px;
  }
  .support-h3 {
    font-size: 25px;
  }
  .popup-container .close-button {
    top: 15px;
    right: 15px;
  }
  .support-p {
    font-size: 12px;
  }
  .book-container {
    height: 70vh;
    flex-direction: column;
    min-height: 500px;
    margin-top: 40px;
    /* background-color: #ae79ef; */
  }
  .horizontal-futuro-gallery {
    height: 45vh;
  }
  .futuro-container {
    flex: 0 0 80%;
    margin-left: 10px;
  }
  .popup-conexion {
    top: 20px;
    bottom: 20px;
    left: 20px;
    right: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column-reverse;
    min-width: 240px;
  }
  .popup-conexion-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
    height: 80vh;
    justify-content: space-evenly;
  }

  .popup-form-cont {
    height: 90%;
  }

  .popup-conexion img {
    width: 230px;
    object-fit: contain;
    margin-bottom: 0px;
  }
  .popup-conexion .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .popup-conexion-title {
    font-size: 30px;
    margin-bottom: 25px;
    text-align: left;
    margin-left: 0px;
    margin-top: 0px;
  }
  .popup-conexion-subtitle {
    font-size: 16px;
    margin-bottom: 0px;
    text-align: left;
    margin-left: 0px;
  }

  .popup-conexion-caracteristicas {
    display: none;
  }
  .contact-me-obras {
    margin-left: 0px;
    margin-top: 20px;
  }
  .horizontal-inad-gallery {
    align-items: center;
  }
  .inad-container {
    flex: 0 0 70%;
  }
  .form-row-mob {
    margin-bottom: -30px;
  }
  .form-row-cont-mob {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .special-form h3 {
    font-size: 20px;
  }
  .special-form input[type="text"] {
    width: 100px;
    font-size: 20px;
  }
  .special-form input[type="number"] {
    width: 50px;
    font-size: 20px;
  }

  .special-form input::placeholder,
  .special-form textarea::placeholder {
    font-size: 20px;
  }
  .special-form textarea {
    font-size: 20px;
    margin-left: -10px;
    margin-top: 20px;
  }
  .footer-mob-popup {
    flex-direction: column;
  }
  .p-popupform {
    font-size: 10px;
    margin-right: 0px;
    width: auto;
    margin-bottom: 0px;
  }
  .popup-container {
    /* max-height: 657px; */
    min-height: 300px;
  }
  .circle-screen {
    display: none;
  }
}

@media (max-height: 650px) and (max-width: 720px) {
  .special-form h3 {
    font-size: 16px;
  }
  .special-form input[type="text"] {
    width: 100px;
    font-size: 16px;
  }
  .special-form input[type="number"] {
    width: 50px;
    font-size: 16px;
  }

  .special-form input::placeholder,
  .special-form textarea::placeholder {
    font-size: 16px;
  }
  .special-form textarea {
    font-size: 16px;
    margin-left: -10px;
    margin-top: 16px;
  }
  .footer-mob-popup {
    margin-top: -85px;
  }
  .p-popupform {
    font-size: 10px;
    margin-right: 0px;
    width: auto;
    padding-top: 20px;
  }
}

@media (max-height: 625px) {
  .popup-conexion img {
    width: 180px;
  }
}

@media (max-width: 450px) and (max-height: 600px) {
  .popup-conexion {
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
  }
  .popup-conexion-title {
    font-size: 23px;
  }
}

@media (max-width: 400px) {
  .email-container {
    width: 250px;
    height: 45px;
    position: relative;
  }
  /* .submit-button {
    position: absolute;
    right: -13px;
    width: 32%;
    height: 112% !important;
    top: 1px;
  } */
  .support-h3 {
    font-size: 20px;
  }
  .support-p {
    font-size: 10px;
  }
}

/* @media (max-height: 690px) {
  .navbar-right-section {
    margin-top: 0px;
  }
} */

@media (max-height: 660px) {
  .navbar-right-section {
    margin-top: 90px;
  }
  .navbar-left-section {
    /* margin-top: -0px; */
  }

  /* .navbar-title {
    font-size: 29px;
  } */
  .navbar-title-mob {
    font-size: 29px;
  }
  .contact-me {
    margin-top: 20px;
  }
  .contact {
    margin-top: 15px;
  }
}

/* !!!!!! */
@media (max-height: 610px) {
  .navbar-right-section {
    margin-top: 60px;
  }
  .navbar-left-section {
    margin-top: -15px;
  }

  .navbar-title-mob {
    font-size: 29px;
  }
  .contact-me {
    margin-top: 10px;
  }
  .contact {
    margin-top: -5px;
  }
  .support-cont {
    justify-content: flex-end;
  }
  .switches-container {
    margin-bottom: -22px;
  }
}

@media (max-height: 580px) {
  .navbar-right-section {
    margin-top: 80px;
  }
  .navbar-left-section {
    margin-top: -15px;
  }

  /* .navbar-title {
    font-size: 27px;
  } */

  .navbar-title-mob {
    font-size: 27px;
  }
  .contact-me {
    margin-top: 10px;
  }
  .contact {
    margin-top: 0px;
  }
  .email-container {
    width: 250px;
    height: 35px;
    position: relative;
  }
  /* .submit-button {
    position: absolute;
    right: -13.6px;
    width: 32%;
    height: 116% !important;
    top: 1px;
  } */
}

@media (max-height: 650px) {
  .right-cont-pop-community {
    margin-top: 5px;
  }
}

@media (max-height: 530px) {
  .contact-me {
    display: none;
  }
}

.larevista-cont-EN {
  height: 100vh;

  background-image: url("../Assets/la-revista-cs-eng.png");
  background-color: #e3cc00;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.larevista-cont-ES {
  height: 100vh;
  background-image: url("../Assets/la-revista-cs.png");
  background-color: #e3cc00;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 720px) {
  .larevista-cont-ES {
    height: 100vh;
    background-image: url("../Assets/larevista-cs-mob.png");
    background-color: #e3cc00;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .larevista-cont-EN {
    height: 100vh;
    background-image: url("../Assets/larevista-cs-mob-eng.png");
    background-color: #e3cc00;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.agenda-cs-cont {
  height: 100vh;
  background-image: url("../Assets/agenda-cs.png");
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 720px) {
  .agenda-cs-cont {
    height: 100vh;
    background-image: url("../Assets/agenda-mob-cs-2.png");
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

@media (width > 750px) and (max-height: 700px) {
  .cont-svgs-3 {
    width: 200px;
    height: auto;
  }
  .comunidad-spike {
    bottom: 30px;
  }
  .obras-spike-button {
    bottom: 275px;
  }
  /* .special-form input::placeholder,
  .special-form textarea::placeholder {
    font-size: 24px;
  } */
}

.obras-spike-button-container {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.obras-spike-button-container.visible {
  opacity: 1;
}

.holahola {
  background-image: url("../Assets/yomor/yomor-prueba.png");
  background-position: right top;
  background-size: cover;
}

.Toastify__toast {
  font-family: "Medium" !important;
  font-size: 16px !important;
  font-style: normal !important;
  line-height: 100.5% !important;
  color: black !important;
  background-image: url("../Assets/toast/frame-toast.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  /* Add more styles as needed */
}
.Toastify__toast-body {
  margin-left: 75px !important;
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 7em !important;
}

.Toastify__toast {
  width: 393px !important;
  height: 98px !important;
  max-height: none !important;
}
.Toastify__toast .Toastify__toast-theme--light .Toastify__toast--default {
}

@media (max-width: 720px) {
  .Toastify__toast-container--top-right {
    top: 0px;
  }
  .Toastify__toast {
    width: 100vw !important;
    height: 98px !important;
    max-height: none !important;
  }
}
